import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { SettingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import AuthDataContext from "context/AuthDataContext";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import "./css/NavProfile.css";
import consoleBackendService from "services/ConsoleBackendService";
import Utils from "utils";
import { GITBOOK_DOCS_URL } from "constants/AppConstant";

const menuItem = [
    {
        title: "Organization Settings",
        icon: SettingOutlined,
        path: `${APP_PREFIX_PATH}/organization/setting`,
    },
];

export const NavSettings = ({ signOut }) => {
    const { orgUserRole } = useContext(AuthDataContext);
    const isNSOnlyRoleView = Utils.isNamespaceOnlyRoleView(orgUserRole);
    const onDocsClick = () => {
        consoleBackendService
            .createGitbookToken()
            .then((res) => {
                const gitbookToken = res.response.items[0].token;
                var moveTo = `https://${GITBOOK_DOCS_URL}/?jwt_token=${gitbookToken}`;
                window.open(moveTo);
            })
            .catch(function (err) {
                // TODO - handle error.
                console.log("Error", err);
            });
    };

    const settingsMenu = (
        <div className="nav-profile nav-dropdown mt-3">
            <div className="nav-profile-body">
                <Menu selectedKeys={[]}>
                    <Menu.Item key={-1} onClick={(e) => onDocsClick()}>
                        <span>
                            <InfoCircleOutlined />
                            <span className="font-weight-normal">Documentation</span>
                        </span>
                    </Menu.Item>
                    {!isNSOnlyRoleView && menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <Icon type={el.icon} />
                                    <span className="font-weight-normal">{el.title}</span>
                                <Link to={el.path} />
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={settingsMenu} trigger={["click"]} className="mx-3">
            <SettingOutlined style={{ fontSize: "28px", cursor: "pointer" }} />
        </Dropdown>
    );
};

export default NavSettings;
