// This reducer is for the Refresh state when we wish to load data of components in a page
// where the page can not know the loading state or have access to the loadData function of that component
export const initialState = {
    refresh: {},
    loadingCounts: {},
};

export const REFRESH_ACTIONS = {
    TOGGLE_REFRESH: "TOGGLE_REFRESH",
    INCREMENT_LOADING: "INCREMENT_LOADING",
    DECREMENT_LOADING: "DECREMENT_LOADING",
};

export const refreshReducer = (state, action) => {
    const { scope } = action.payload;

    if (!scope) {
        return state;
    }

    switch (action.type) {
        case REFRESH_ACTIONS.TOGGLE_REFRESH:
            return {
                ...state,
                refresh: {
                    ...state.refresh,
                    [scope]: (state.refresh[scope] || 0) + 1,
                },
            };
        case REFRESH_ACTIONS.INCREMENT_LOADING:
            return {
                ...state,
                loadingCounts: {
                    ...state.loadingCounts,
                    [scope]: (state.loadingCounts[scope] || 0) + 1,
                },
            };
        case REFRESH_ACTIONS.DECREMENT_LOADING:
            return {
                ...state,
                loadingCounts: {
                    ...state.loadingCounts,
                    [scope]: Math.max((state.loadingCounts[scope] || 1) - 1, 0),
                },
            };
        default:
            return state;
    }
};
