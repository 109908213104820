import { ORGANIZATION_SETTINGS, ROLE } from "constants/AppConstant";
import { ROUTES } from "./AppRoutesConfig";

// default logged in users homepage
export const ORG_USER_HOME_PAGE = {
    DEFAULT: ROUTES.DASHBOARD, // default for all users
    [ROLE.NS_ONLY.key]: ROUTES.NAMESPACES, // default for namespace only role
    [ORGANIZATION_SETTINGS.TYPE.MANAGEMENT]: ROUTES.ORGANIZATION, // default for organization which is type of management
    [ORGANIZATION_SETTINGS.TYPE.REGULAR_NO_AWS]: ROUTES.NAMESPACES // default for organization which is regular BUT does not have AWS account
};

// Routes allowed routes by organization type
export const ORGANIZATION_ALLOWED_ACL_ROUTES = {
    // allowed path routes for regular organization
    [ORGANIZATION_SETTINGS.TYPE.REGULAR]: [
        ROUTES.DASHBOARD,
        ROUTES.AWS_DASHBOARD,
        // ROUTES.AZURE_DASHBOARD,
        ROUTES.IAC_IMPORT,
        ROUTES.RESOURCE_FINDER,
        ROUTES.TF_EXPLORER,
        ROUTES.TF_REGISTRY,
        ROUTES.TF_MODULE_DETAILS,
        ROUTES.CREATE_TF_MODULE_WIZARD,
        ROUTES.EDIT_TF_MODULE_WIZARD,
        ROUTES.TEMPLATES,
        ROUTES.TEMPLATE,
        ROUTES.DRIFT_CENTER,
        ROUTES.CONTROL_POLICIES,
        ROUTES.POLICY_VIOLATIONS,
        ROUTES.SECURITY_STANDARD,
        ROUTES.CONTROL_POLICY_GROUP,
        ROUTES.CREATE_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.STACK,
        ROUTES.DEPLOYMENT,
        ROUTES.PLAN,
        ROUTES.TASK,
        ROUTES.REMOTE_RUN,
        ROUTES.STACKS,
        ROUTES.CREATE_TEMPLATE_WIZARD,
        ROUTES.EDIT_TEMPLATE_WIZARD,
        ROUTES.CREATE_BLUEPRINT_WIZARD,
        ROUTES.EDIT_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD,
        ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_WIZARD,
        ROUTES.EDIT_STACK_WIZARD,
        ROUTES.IMPORT_STACK_WIZARD,
        ROUTES.IMPORT_TO_EXISTING_STACK_WIZARD,
        ROUTES.IMPORT_BULK_STACKS_WIZARD,
        ROUTES.CREATE_NAMESPACE_WIZARD,
        ROUTES.EDIT_NAMESPACE_WIZARD,
        ROUTES.NAMESPACES,
        ROUTES.NAMESPACE,
        ROUTES.STACK_DISCOVERY,
        ROUTES.CREATE_STACK_DISCOVERY_WIZARD,
        ROUTES.EDIT_STACK_DISCOVERY_WIZARD,
        ROUTES.USER,
        ROUTES.TEAM,
        ROUTES.ORGANIZATION,
        ROUTES.PERSONAL_ACCESS_TOKEN,
        ROUTES.CREATE_MODEL_WIZARD,
        ROUTES.AUDIT,
        ROUTES.CREATE_CONTROL_POLICY_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_WIZARD,
        ROUTES.CLOUD_EVENTS,
        ROUTES.ACTIVE_RUNS,
        ROUTES.DOCS_FALLBACK,
        ROUTES.REPORTS,
        ROUTES.CREATE_STACK_SET_WIZARD,
        ROUTES.POST_GITHUB_INSTALLATION,
    ],
    [ORGANIZATION_SETTINGS.TYPE.MANAGEMENT]: [
        ROUTES.ORGANIZATION,
        ROUTES.USER,
        ROUTES.TEAM,
    ],
    // allowed path routes for regular organization type BUT without AWS accounts
    [ORGANIZATION_SETTINGS.TYPE.REGULAR_NO_AWS]: [
        // PAGES.DASHBOARD,
        // PAGES.AWS_DASHBOARD,
        // PAGES.AZURE_DASHBOARD,
        // PAGES.IAC_IMPORT,
        ROUTES.RESOURCE_FINDER,
        ROUTES.TF_EXPLORER,
        ROUTES.TEMPLATES,
        ROUTES.TEMPLATE,
        ROUTES.DRIFT_CENTER,
        ROUTES.CONTROL_POLICIES,
        ROUTES.POLICY_VIOLATIONS,
        ROUTES.SECURITY_STANDARD,
        ROUTES.CONTROL_POLICY_GROUP,
        ROUTES.CREATE_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.STACK,
        ROUTES.DEPLOYMENT,
        ROUTES.PLAN,
        ROUTES.TASK,
        ROUTES.REMOTE_RUN,
        ROUTES.STACKS,
        ROUTES.CREATE_TEMPLATE_WIZARD,
        ROUTES.EDIT_TEMPLATE_WIZARD,
        ROUTES.CREATE_BLUEPRINT_WIZARD,
        ROUTES.EDIT_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD,
        ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_WIZARD,
        ROUTES.EDIT_STACK_WIZARD,
        // ROUTES.IMPORT_STACK_WIZARD,
        // ROUTES.IMPORT_TO_EXISTING_STACK_WIZARD,
        // ROUTES.IMPORT_BULK_STACKS_WIZARD,
        ROUTES.CREATE_NAMESPACE_WIZARD,
        ROUTES.EDIT_NAMESPACE_WIZARD,
        ROUTES.NAMESPACES,
        ROUTES.NAMESPACE,
        ROUTES.STACK_DISCOVERY,
        ROUTES.CREATE_STACK_DISCOVERY_WIZARD,
        ROUTES.EDIT_STACK_DISCOVERY_WIZARD,
        ROUTES.USER,
        ROUTES.TEAM,
        ROUTES.ORGANIZATION,
        ROUTES.PERSONAL_ACCESS_TOKEN,
        // ROUTES.CREATE_MODEL_WIZARD,
        ROUTES.AUDIT,
        ROUTES.CREATE_CONTROL_POLICY_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_WIZARD,
        // PAGES.CLOUD_EVENTS,
        ROUTES.ACTIVE_RUNS,
        ROUTES.DOCS_FALLBACK,
        ROUTES.REPORTS,
        ROUTES.POST_GITHUB_INSTALLATION,
    ],
    [ORGANIZATION_SETTINGS.TYPE.REGULAR_AZURE]: [
        ROUTES.DASHBOARD,
        // PAGES.AWS_DASHBOARD,
        ROUTES.AZURE_DASHBOARD,
        // PAGES.IAC_IMPORT,
        ROUTES.RESOURCE_FINDER,
        ROUTES.TF_EXPLORER,
        ROUTES.TEMPLATES,
        ROUTES.TEMPLATE,
        ROUTES.DRIFT_CENTER,
        ROUTES.CONTROL_POLICIES,
        ROUTES.POLICY_VIOLATIONS,
        ROUTES.SECURITY_STANDARD,
        ROUTES.CONTROL_POLICY_GROUP,
        ROUTES.CREATE_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.STACK,
        ROUTES.DEPLOYMENT,
        ROUTES.PLAN,
        ROUTES.TASK,
        ROUTES.REMOTE_RUN,
        ROUTES.STACKS,
        ROUTES.CREATE_TEMPLATE_WIZARD,
        ROUTES.EDIT_TEMPLATE_WIZARD,
        ROUTES.CREATE_BLUEPRINT_WIZARD,
        ROUTES.EDIT_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD,
        ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_WIZARD,
        ROUTES.EDIT_STACK_WIZARD,
        // ROUTES.IMPORT_STACK_WIZARD,
        // ROUTES.IMPORT_TO_EXISTING_STACK_WIZARD,
        // ROUTES.IMPORT_BULK_STACKS_WIZARD,
        ROUTES.CREATE_NAMESPACE_WIZARD,
        ROUTES.EDIT_NAMESPACE_WIZARD,
        ROUTES.NAMESPACES,
        ROUTES.NAMESPACE,
        ROUTES.STACK_DISCOVERY,
        ROUTES.CREATE_STACK_DISCOVERY_WIZARD,
        ROUTES.EDIT_STACK_DISCOVERY_WIZARD,
        ROUTES.USER,
        ROUTES.TEAM,
        ROUTES.ORGANIZATION,
        ROUTES.PERSONAL_ACCESS_TOKEN,
        // ROUTES.CREATE_MODEL_WIZARD,
        ROUTES.AUDIT,
        ROUTES.CREATE_CONTROL_POLICY_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_WIZARD,
        // PAGES.CLOUD_EVENTS,
        ROUTES.ACTIVE_RUNS,
        ROUTES.DOCS_FALLBACK,
        ROUTES.REPORTS,
        ROUTES.POST_GITHUB_INSTALLATION,
    ],
    [ORGANIZATION_SETTINGS.TYPE.REGULAR_AWS_AND_AZURE]: [
        ROUTES.DASHBOARD,
        ROUTES.AWS_DASHBOARD,
        ROUTES.AZURE_DASHBOARD,
        ROUTES.GCP_DASHBOARD,
        ROUTES.IAC_IMPORT,
        ROUTES.RESOURCE_FINDER,
        ROUTES.TF_EXPLORER,
        ROUTES.TF_REGISTRY,
        ROUTES.TF_MODULE_DETAILS,
        ROUTES.CREATE_TF_MODULE_WIZARD,
        ROUTES.EDIT_TF_MODULE_WIZARD,
        ROUTES.TEMPLATES,
        ROUTES.TEMPLATE,
        ROUTES.DRIFT_CENTER,
        ROUTES.CONTROL_POLICIES,
        ROUTES.POLICY_VIOLATIONS,
        ROUTES.SECURITY_STANDARD,
        ROUTES.CONTROL_POLICY_GROUP,
        ROUTES.CREATE_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_GROUP_WIZARD,
        ROUTES.STACK,
        ROUTES.DEPLOYMENT,
        ROUTES.PLAN,
        ROUTES.TASK,
        ROUTES.REMOTE_RUN,
        ROUTES.STACKS,
        ROUTES.CREATE_TEMPLATE_WIZARD,
        ROUTES.EDIT_TEMPLATE_WIZARD,
        ROUTES.CREATE_BLUEPRINT_WIZARD,
        ROUTES.EDIT_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD,
        ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_WIZARD,
        ROUTES.EDIT_STACK_WIZARD,
        ROUTES.IMPORT_STACK_WIZARD,
        ROUTES.IMPORT_TO_EXISTING_STACK_WIZARD,
        ROUTES.IMPORT_BULK_STACKS_WIZARD,
        ROUTES.CREATE_NAMESPACE_WIZARD,
        ROUTES.EDIT_NAMESPACE_WIZARD,
        ROUTES.NAMESPACES,
        ROUTES.NAMESPACE,
        ROUTES.STACK_DISCOVERY,
        ROUTES.CREATE_STACK_DISCOVERY_WIZARD,
        ROUTES.EDIT_STACK_DISCOVERY_WIZARD,
        ROUTES.USER,
        ROUTES.TEAM,
        ROUTES.ORGANIZATION,
        ROUTES.PERSONAL_ACCESS_TOKEN,
        ROUTES.CREATE_MODEL_WIZARD,
        ROUTES.AUDIT,
        ROUTES.CREATE_CONTROL_POLICY_WIZARD,
        ROUTES.EDIT_CONTROL_POLICY_WIZARD,
        ROUTES.CLOUD_EVENTS,
        ROUTES.ACTIVE_RUNS,
        ROUTES.DOCS_FALLBACK,
        ROUTES.REPORTS,
        ROUTES.CREATE_STACK_SET_WIZARD,
        ROUTES.POST_GITHUB_INSTALLATION,
    ]
}

// User allowed routes by user type, if user type is not set it should allow the path unless excplicitly coded here
export const USER_ALLOWED_ACL_ROUTES = {
    [ROLE.NS_ONLY.key]: [
        ROUTES.STACK,
        ROUTES.DEPLOYMENT,
        ROUTES.PLAN,
        ROUTES.TASK,
        ROUTES.REMOTE_RUN,
        ROUTES.STACKS,
        ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD,
        ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD,
        ROUTES.CREATE_STACK_WIZARD,
        ROUTES.EDIT_STACK_WIZARD,
        ROUTES.NAMESPACES,
        ROUTES.NAMESPACE,
        ROUTES.USER,
        ROUTES.DOCS_FALLBACK,
        ROUTES.PERSONAL_ACCESS_TOKEN,
    ]
}
