import React from "react";
import TooltipDrawer from "components/shared-components/TooltipDrawer/TooltipDrawer";

const LabelWithTooltip = (props) => {
    const { label, tooltip } = props;
    if (!label) {
        return null;
    }

    return (
        <div style={{whiteSpace: 'nowrap'}}>
            <span className="mr-2">{label}</span>
            {tooltip && <TooltipDrawer data={tooltip} />}
        </div>
    );
};

export default LabelWithTooltip;
