import React, {useState, useEffect} from 'react';
import consoleBackendService from "services/ConsoleBackendService";

const AuthDataContext = React.createContext({});

// custom hook for loading user type
const useGetOrgUserRole = () => {
    const [isLoadingOrgUserRole, setIsLoadingOrgUserRole] = useState(true);
    const [orgUserRole, setOrgUserRole] = useState();
    const [orgUserTeams, setOrgUserTeams] = useState();

    useEffect(() => {
        consoleBackendService.getOrgUserRole().then((res) => {
            const orgUserRoleRes = res.response.items[0];
            setOrgUserRole(orgUserRoleRes.orgRole);
            setOrgUserTeams(orgUserRoleRes?.teams || []);
        }).catch(function (err) {
            console.log("Error, could not get user type data", err);
        }).finally(() => {
            setIsLoadingOrgUserRole(false);
        });
    }, []);

    return {
        orgUserRole,
        orgUserTeams,
        isLoadingOrgUserRole
    };
}

// context provider for user data such as user type
export const AuthDataProvider = ({children}) => {
    const state = useGetOrgUserRole();
    return <AuthDataContext.Provider value={state}>{children}</AuthDataContext.Provider>
}

export default AuthDataContext;
