const local = {
	API_ENDPOINT_URL: 'http://localhost:2000'
};

const dev = {
  API_ENDPOINT_URL: 'https://console-backend-dev.controlmonkey.io'
};

const prod = {
  API_ENDPOINT_URL: 'https://console-backend.controlmonkey.io'
};


const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'dev':
			return dev
		case 'prod':
			return prod
		case 'local': 
			return local;
		default:
			return local;
	}
}

export const env = getEnv()
