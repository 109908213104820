import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	
} from "../actions/Auth";
import localStorageHelper from 'helpers/localStorageHelper';

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			// TODO - send request to the console-backend to revoke the token.
			localStorageHelper.onSignOut();
			yield put(signOutSuccess(undefined));
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}



export default function* rootSaga() {
  yield all([
	fork(signOut)
  ]);
}
