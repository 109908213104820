import React from 'react'
import { Switch, Route, } from "react-router-dom";
import AuthPages from 'pages/auth-pages'

export const AuthLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={AuthPages} />
			</Switch>
		</div>
	)
}


export default AuthLayout
