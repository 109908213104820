import React, {useState} from 'react';
const NanoStateContext = React.createContext({});

const useNanoState = () => {
    // create new custom model from existing suggested stack
    const [suggestedStackData, setSuggestedStackData] = useState({});

    // stack resources tab set the resources while other places in the stack page uses them
    const [stackResources, setStackResources] = useState([]);

    // stack drift detection result
    const [stackDriftDetectionResult, setStackDriftDetectionResult] = useState({});

    // bulk import suggested stacks
    const [importSuggestedStacks, setImportSuggestedStacks] = useState([]);

    return {
        suggestedStackData,
        setSuggestedStackData,

        stackResources,
        setStackResources,

        stackDriftDetectionResult,
        setStackDriftDetectionResult,

        importSuggestedStacks,
        setImportSuggestedStacks
    };
}

export const NanoStateProvider = ({children}) => {
    const state = useNanoState();
    return <NanoStateContext.Provider value={state}>{children}</NanoStateContext.Provider>
}

export default NanoStateContext;
