import React from "react";
import { Row, Col, Form, Input, Select } from "antd";
import LabelWithTooltip from "components/shared-components/LabelWithTooltip";
import { VARIABLE_WIZARD_CONDITIONS } from "constants/TooltipConstant";

export const cmOperators = [
    // { name: "eq", label: "=" }
    { name: "ne", label: "!=" },
    { name: "lt", label: "<" },
    { name: "lte", label: "<=" },
    { name: "gt", label: ">" },
    { name: "gte", label: ">=" },
    { name: "startsWith", label: "Starts with" },
    { name: "contains", label: "Contains" },
    { name: "in", label: "In" },
    { name: "match", label: "Match" },
    { name: "boolean", label: "Boolean" },
];

export const numberOperators = ["lt", "lte", "gt", "gte"];
export const stringOperators = ["ne", "startsWith", "contains", "in"];

export const ValueConditionRule = ({ form }) => {
    return (
        <>
            <div className="mb-2 font-weight-semibold"><LabelWithTooltip label="Set Condition for value:" tooltip={VARIABLE_WIZARD_CONDITIONS} /></div>
            <Row span={24} gutter={16}>
                <Col span={8}>
                    <Form.Item shouldUpdate noStyle rule={[{ required: true, message: "please" }]}>
                        {() => {
                            const val = form.getFieldValue(["setConditions", "value"]);

                            return (
                                <Form.Item
                                    name={["setConditions", "operator"]}
                                    rules={[
                                        {
                                            // required if value exists
                                            required: !!val,
                                            message: "Select operator",
                                        },
                                    ]}>
                                    <Select
                                        style={{ minWidth: 20 }}
                                        dropdownStyle={{ minWidth: 150 }}
                                        showArrow
                                        allowClear
                                        filterOption={(input, option) => {
                                            return (
                                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        placeholder="Operator">
                                        {cmOperators.map((op) => (
                                            <Select.Option key={op.name} value={op.name}>
                                                {op.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item shouldUpdate noStyle>
                        {() => {
                            const op = form.getFieldValue(["setConditions", "operator"]);
                            const isBoolean = op === "boolean";
                            let placeholder = "Value";
                            if (op === "in") {
                                placeholder = "val1,val2,val3";
                            }

                            if (isBoolean) {
                                // boolean value will be set upon template creation
                                return null;
                            }

                            return (
                                <Form.Item
                                    name={["setConditions", "value"]}
                                    validateFirst
                                    rules={[
                                        {
                                            // required if operator is selected
                                            required: op || false,
                                            message:
                                                op === "in" ? "Please enter value(s)" : "Please enter value",
                                        },
                                        {
                                            validator: (_, value) => {
                                                // filter out empty long string
                                                if (typeof value === 'string' && value.length > 0) {
                                                    return value.trim()
                                                    ? Promise.resolve()
                                                    : Promise.reject(op === "in" ? "Please enter value(s)" : "Please enter value")

                                                }
                                                
                                                // it does not have any value, ignore
                                                return Promise.resolve();
                                            }
                                        },
                                        {
                                            validator: async (_, input) => {
                                                const op = form.getFieldValue(["setConditions", "operator"]);
                                                if (numberOperators.includes(op)) {
                                                    // operators are for number input
                                                    const isStringValue = isNaN(Number(input));
                                                    if (isStringValue) {
                                                        return Promise.reject(
                                                            `For operator '${op}' property value must be a number`
                                                        );
                                                    }
                                                }

                                                // All good
                                                return Promise.resolve();
                                            },
                                        },
                                        {
                                            validator: async (_, input) => {
                                                const op = form.getFieldValue(["setConditions", "operator"]);
                                                if (op === "match") {
                                                    // we need to check that the input is a valid regex
                                                    try {
                                                        new RegExp(input);
                                                        return Promise.resolve();
                                                      } catch (e) {
                                                        return Promise.reject(`For operator '${op}' the input is not a valid regex pattern`);
                                                    }
                                                }

                                                // All good
                                                return Promise.resolve();
                                            },
                                        }
                                    ]}>
                                    <Input placeholder={placeholder} onChange={() => form.validateFields([["setConditions", "operator"]])} />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
