export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const AWS_ACCOUNT_ID = 'awsAccountId'
export const AWS_REGION_ID = 'awsRegionId'
export const AZURE_SUBSCRIPTION_ID = 'azureSubscriptionId'
export const AZURE_REGION_ID = 'azureRegionId'
export const GCP_PROJECT_ID = 'gcpProjectId'
export const GCP_REGION_ID = 'gcpRegionId'
export const USER = 'user'
export const ORGANIZATION = 'organization'
export const LASTEST_NOTIFICATION_DATE = 'latestNotificationDate'