import { createContext, useState, useEffect } from 'react';
import consoleBackendService from 'services/ConsoleBackendService';
import utils_lodash from 'lodash';
import Utils from 'utils';
import { AWS_GLOBAL_AND_REGIONAL_SERVICES, AWS_GLOBAL_RESOURCE_TYPES, AWS_GLOBAL_SERVICES, AWS_SERVICE_TYPE, CLOUD_CREDS_PROVIDER_TYPE, CLOUD_CREDS_TYPE, VCS_PROVIDER } from 'constants/AppConstant';

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
    const [vcsProviders, setVcsProviders] = useState([]);
    const [awsResourceTypes, setAwsResourceTypes] = useState([])
    const [awsServices, setAwsServices] = useState([]);
    const [terraformVersions, setTerraformVersions] = useState([]);
    const [terragruntVersions, setTerragruntVersions] = useState([]);
    const [opentofuVersions, setOpentofuVersions] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [cloudCredsDiscovery, setCloudCredsDiscovery] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const loadData = () => {
        setIsLoading(true);
        const vcsProvidersPromise = consoleBackendService.getVcsProviders();
        const awsResourceTypesPromise = consoleBackendService.getCollectedAwsResourceTypes();
        const terraformVersionsPromise = consoleBackendService.getTerraformVersions();
        const terragruntVersionsPromise = consoleBackendService.getTerragruntVersions();
        const opentofuVersionsPromise = consoleBackendService.getOpenTofuVersions();
        const organizationsPromise = consoleBackendService.getOrganizationsConnections();
        // credentials for dashboard redirect and selection on organiztion type of multiple providers
        const awsCredsPromise = consoleBackendService.getAwsCreds();
        const azureCredsPromise = consoleBackendService.getAzureCreds();
        const gcpCredsPromise = consoleBackendService.getGcpCreds();
        Promise.all([vcsProvidersPromise, awsResourceTypesPromise, terraformVersionsPromise, terragruntVersionsPromise, opentofuVersionsPromise, organizationsPromise, awsCredsPromise, azureCredsPromise, gcpCredsPromise]).then((responses) => {
            const vcsProvidersRes = responses[0].response.items || [];
            const awsResourceTypesRes = responses[1].response.items;
            let terraformVersionsRes = responses[2].response.items?.[0]?.terraformVersions || [];
            let terragruntVersionsRes = responses[3].response.items?.[0]?.terragruntVersions || [];
            let opentofuVersionsRes = responses[4].response.items?.[0]?.opentofuVersions || [];
            const organizationsRes = responses[5].response.items || [];
            const awsAccountList = responses[6].response.items || [];
            const azureAccountList = responses[7].response.items || [];
            const gcpAccountList = responses[8].response.items || [];

            terraformVersionsRes = Utils.semVerTransformVersions(terraformVersionsRes);
            terragruntVersionsRes = Utils.semVerTransformVersions(terragruntVersionsRes);
            opentofuVersionsRes = Utils.semVerTransformVersions(opentofuVersionsRes);

            const resourceTypesNames = awsResourceTypesRes.map(item => ({
                key: item.name,
                fullName: item.name,
                service: item.service,
                isCodeGenerationSupported: item.isCodeGenerationSupported,
                displayName: item.name.substring(5), // remove the 'AWS::' prefix
                isGlobal: AWS_GLOBAL_RESOURCE_TYPES.some((rt) => item.name.includes(rt)) // check if resource type counts as global
            }));
            const sortedTypes = utils_lodash.sortBy(resourceTypesNames, 'displayName');

            // Build aws services data.
            const groupByService = utils_lodash.groupBy(awsResourceTypesRes, item => {
                return item.service;
            });

            let awsServices = [];
            for (const [key, value] of Object.entries(groupByService)) {
                // check which service type if global or regional or both
                let serviceType = AWS_SERVICE_TYPE.REGIONAL; // default

                if (AWS_GLOBAL_SERVICES.includes(key)) {
                    serviceType = AWS_SERVICE_TYPE.GLOBAL;
                } else if (AWS_GLOBAL_AND_REGIONAL_SERVICES.includes(key)) {
                    serviceType = AWS_SERVICE_TYPE.GLOBAL_AND_REGIONAL;
                }

                awsServices.push({ key: key, displayName: key, serviceType });
            }

            const sortedServices = utils_lodash.sortBy(awsServices, 'displayName');
            // filter out providers such as App configuration, can add more in the future
            const vcsProvidersWithoutApps = vcsProvidersRes.filter(p => p.type !== VCS_PROVIDER.GITHUB_ENTERPRISE_APP);

            // credentials from providers filtered by type discovery and sorted by createdAt property
            const cloudCredsDiscoveryMap = {
                [CLOUD_CREDS_PROVIDER_TYPE.AWS]: awsAccountList.filter((a) => a.type === CLOUD_CREDS_TYPE.visibility.value).sort(Utils.sortByCreatedAt),
                [CLOUD_CREDS_PROVIDER_TYPE.AZURE]: azureAccountList.filter((a) => a.type === CLOUD_CREDS_TYPE.visibility.value).sort(Utils.sortByCreatedAt),
                [CLOUD_CREDS_PROVIDER_TYPE.GCP]: gcpAccountList.filter((a) => a.type === CLOUD_CREDS_TYPE.visibility.value).sort(Utils.sortByCreatedAt)
            };

            setAwsServices(sortedServices);
            setAwsResourceTypes(sortedTypes);
            setVcsProviders(vcsProvidersWithoutApps);
            setTerraformVersions(terraformVersionsRes);
            setTerragruntVersions(terragruntVersionsRes);
            setOpentofuVersions(opentofuVersionsRes);
            setOrganizations(organizationsRes);
            setCloudCredsDiscovery(cloudCredsDiscoveryMap);
            setIsLoading(false);

        }).catch(function (err) {
            // TODO - handle error
            console.log("Error", err);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return <AppDataContext.Provider value={{
        vcsProviders, awsResourceTypes, awsServices, terraformVersions, terragruntVersions, opentofuVersions, organizations, cloudCredsDiscovery, isLoading, loadData
    }}>
        {children}
    </AppDataContext.Provider>
}

export default AppDataContext;
