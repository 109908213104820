import React, { createContext, useContext, useReducer } from "react";
import { refreshReducer, initialState, REFRESH_ACTIONS } from "reducers/refreshReducer";

const RefreshContext = createContext();

export const useRefresh = () => useContext(RefreshContext);

export const RefreshProvider = ({ children }) => {
    const [state, dispatch] = useReducer(refreshReducer, initialState);

    const triggerRefresh = (scope) => {
        dispatch({ type: REFRESH_ACTIONS.TOGGLE_REFRESH, payload: { scope } });
    };

    const incrementLoading = (scope) => {
        dispatch({ type: REFRESH_ACTIONS.INCREMENT_LOADING, payload: { scope } });
    };

    const decrementLoading = (scope) => {
        dispatch({ type: REFRESH_ACTIONS.DECREMENT_LOADING, payload: { scope } });
    };

    return (
        <RefreshContext.Provider value={{ state, triggerRefresh, incrementLoading, decrementLoading }}>
            {children}
        </RefreshContext.Provider>
    );
};
